@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

* {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    background-color: #e7ebf0;
}

.container {
    width: 100%;
    margin: 20px auto;
    max-width: 1500px;
}

.center {
    display: flex;
    justify-content: center;
}

.hide {
    display: none;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
