#form-response{
  position:absolute;
  margin: auto;
  bottom:42%;
  left:48%;
  font-size: 4vh;
  color: #09d3ac;
  // background: white;
  margin: -.3em;
  // padding: .3em 1em;
  // border-radius: 8px;
  // box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
 
}
@media only screen and (max-width: 750px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  #form-response{
    position:absolute;
    margin: auto;
    bottom:-4%;
    left:45%;
    font-size: 4vh;
    color: #09d3ac;
    // background: white;
    // margin-top: -1em;
    // padding-top: 5px;
    // border-radius: 8px;
    // box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
   
  }

}

.wrapper {
  display: flex;
}
.columncontact {
  display: flex;
  flex-direction: column;
}
.columncontact > div {
  font-size: 1em;
  color: #09d3ac;
  background: white;
  margin: .4em;
  padding: .3em 1em;
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  flex: 1;
}

label{
  color: #09d3ac;
  font-size: 24px!important;
}

// button,input,optgroup,select,textarea{font-size:50%;line-height:1.15;margin:0}

* {
  box-sizing: border-box;
}
// .App {
//    max-width: 420px;
//    margin: 0 auto;
// }

textarea {
  width: 300px;
  height: 150px;
}
input[type=text],input[type=email], select, textarea {
   width: 100%;
   padding: 12px;
   font-size: 1em !important;
   color: black !important;  
   border: 1px solid #ccc !important;
   border-radius: 8px  !important;
   box-sizing: border-box;
   margin-top: 6px;
   margin-bottom: 16px;
   resize: vertical;
   
} 
::-webkit-input-placeholder { /* Edge */
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: .5em !important;
}

:-ms-input-placeholder { /* Internet Explorer */
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: .5em !important;
}

::placeholder {
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: 1em !important;
}

input[type=submit] {
   background-color: #09d3ac;
   color: white;
   padding: 12px 20px;
   border: none;
   font-size: 1em !important;
   border-radius: 8px;
   cursor: pointer;
}
input[type=submit]:hover {
   background-color: #067c65;
}
.container {
   border-radius: 8px;
   padding: 20px;
}